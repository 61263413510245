.mb-5 {
  margin-bottom: 1.5rem !important;
}

.control.has-icons-left .input,
.control.has-icons-left .select select {
  padding-left: 0.5em !important;
}

/* .select.is-fullwidth select {
  width: 491px !important;
} */

/* Rounded border */
hr.rounded {
  border-top: 8px solid #382e18;
  /* border-radius: 5px; */
  width: 200px;
  margin: auto;
}


.button-background {
  background-color: #382E17 !important;
  color: white !important;
}