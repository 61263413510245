.main-footer {
    padding: 40px 0;
    font-size: 15px;
    background-color: #191919;
    color: #a8a8a8;
    line-height: 1.5;
}

.container {
    max-width: 1200px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
}

h2,
h3 {
    font-size: 24px;
    color: #f0f0f0;
    margin-bottom: 20px;
    font-weight: 500;
}

.slogan {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #a8a8a8;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

a {
    color: #a8a8a8;
    text-decoration: none;
}

a:hover {
    color: #f0f0f0;
}